import React from "react";
import { Link } from "gatsby-plugin-react-intl";
import Seo from "../components/layout/seo";
import LayoutSimple from "../components/layout/layoutSimple";

const PrivacyPolicy = () => {
    return (
        <LayoutSimple>
            <Seo title="Privacy Notice" />
            <div className="flex justify-center w-full mt-12 p-8">
                <div className="max-w-xl">
                    <div className="mt-10 text-justify">
                        <h1 className="text-3xl font-semibold py-2">Privacy Policy</h1>


                        <p className="italic py-4">Updated February 2023</p>
                        <p>
                            In this privacy policy (the “Privacy Policy”) you can learn more about how the entities part of the Reel group (“Reel”, “we” or “us”) processes your personal data in various situations.
                            We provide you with this information as we are required to do so under the EU General Data Protection regulation (the "GDPR").
                        </p>

                        <h2 className="text-xl font-semibold pt-4 pb-2">1.	Data Controller</h2>
                        <p>The entities forming part of Reel group processes personal data in various situations. Below, please find information of these processing purposes, including which group company acting as a data controller in the specific situations.
                            In Section 5 you can read about your rights, and in Section 7 you can read more about how to contact us.</p>

                        <h2 className="text-xl font-semibold pt-4 pb-2">2.	Information we collect, purpose, and legal basis</h2>
                        <p>This Privacy Policy covers the processing of personal data in the following situations:</p>
                        <ol className="list-decimal pb-2 pl-8">
                            <li className="py-1">Recruitment,</li>
                            <li className="py-1">General communications,</li>
                            <li className="py-1">Customer administration,</li>
                            <li className="py-1">Direct marketing and marketing activities, and/or</li>
                            <li className="py-1">When you visiti our website(s).</li>
                        </ol>

                        <h3 className="text-lg font-medium py-2">2.1 Recruitment</h3>
                        <p className="pb-4">The controller is Reel ApS. Please see the contact information under Section 7 below.</p>
                        <p className="pb-4">When we receive your application, we will read it and select the persons who we will call for an interview. The selection is based on your qualifications in relation to the specific position(s). You will be called for the interview by email or telephone.</p>
                        <p className="pb-4">As part of the recruitment process, we will receive and process the personal data that you have included in your application, CV and any other material which you may have forwarded along with your application. We may also ask you to send us additional information. The information obtained in this connection will include information about your previous employments, including information relating to work assignments, skills and performance, and information about your personal appearance and interpersonal skills. We will also obtain other information about you if we consider such information to be necessary for the assessment of your application.</p>
                        <p className="pb-4">The legal basis for processing the personal data is article 6(1)(f) of the GDPR as we pursue our legitimate interest in reviewing your application and CV. Further, in case we proceed with the application process, and we need to prepare an employment contract, our legal basis is article 6(1)(b) of the GDPR.</p>
                        <p className="pb-4">If we wish to obtain information about you from your current or former employer using your references, we will first ask for your consent. The legal basis is the consent you give for the purpose of using your references; see article 6(1)(a) of the GDPR. You always have the opportunity to withdraw your consent.</p>
                        <p className="pb-4">During the recruitment process relating to some positions, we may use a personality test and/or a competency test. Typically, the tests are taken after our first interview with you. The purpose of the tests is to identify your personal preferences and skills, forming a basis for a dialogue with you about your personal resources and conduct. The tests will be part of the overall basis for selecting the right candidate for the position. The legal basis for processing the said personal data is article 6(1)(f) of the GDPR as we pursue our above-mentioned legitimate interests.</p>
                        <p className="pb-4">We share your personal data with our recruitment partner, LinkedIn, and application submission and applicant tracking tool Homerun. Your personal data in relation to the recruitment process will be deleted within 6 months after being notified of the rejection.</p>

                        <h3 className="text-lg font-medium py-2">2.2 General communications</h3>
                        <p className="pb-3">If you communicate with us (e.g. via email), the controller for the personal data processed in that regard is Reel ApS. Please see the contact information under Section 7 below.</p>
                        <p className="pb-3">Your communication will contain personal data, such as your contact details (including name and email address) and other personal data you may provide us with.</p>
                        <p className="pb-3">We process these personal data for the purpose of managing and answering your inquiries. The legal basis for the processing is article 6(1)(f) of the GDPR, as we are pursuing our legitimate interests in managing general inquiries.</p>
                        <p className="pb-3">We only keep your data for as long as they are of relevance to us and we have a legitimate purpose for keeping them. Your data will therefore be deleted in accordance with applicable data protection legislation. Personal data pertaining to our general communication with you (such as email inquiries) will be deleted 2 year safter the end of the financial year where your last inquiry has been handled/concluded.</p>

                        <h3 className="text-lg font-medium py-2">2.3 Customer administration etc.</h3>
                        <p className="pb-3">Customer administration and use of Reel Customer Portal</p>
                        <p className="pb-3">In customer relationships we will process your contact details and your association with the company that you represent. The controller is the group entity, including a SPV, that is party to the contract with the customer that you represent. Please see the contact information under Section 7 below.</p>
                        <p className="pb-3">We may also receive such personal data from a third party, such as your employer or other companies (if your personal data is mentioned in such material that we receive).</p>
                        <p className="pb-3">We process this personal data for the purpose of identifying who you are, managing and answering your inquiries and orders and to communicate with you/the company you represent.</p>
                        <p className="pb-3">Further, if you sign-up for the Reel Customer Portal, we process your email address (if this is a personal address) and the password you generate (if this is linked to a person).</p>
                        <p className="pb-3">The legal basis for the processing is article 6(1)(f) of the GDPR, as we are pursuing our legitimate interests in managing general inquiries, providing customer support, and fulfilling any agreement we may have concluded with the company you represent.</p>
                        <p className="pb-3">Your personal data will generally be deleted 2 years after the end of the financial year in which the customer relationship has ended. With regard to the Reel Customer Portal, personal data will be deleted when the log on/account has been inactive for 2 years. In specific situations, we may defer from our general retention periods (in case of e.g. complaints, objections or other specific situations).</p>
                        <h4 className="pb-3 italic font-medium">Bookkeeping obligations</h4>
                        <p className="pb-3">As part of processing of invoices etc., personal data listed in such material will also be processed (such as names and contact details). The controller for that processing activity is the group entity that is party to the contract in which an invoice has been issued. Please see the contact information under Section 7 below.</p>
                        <p className="pb-3">We are obligated to store bookkeeping information, including information related to payments/transactions in accordance with the Danish bookkeeping legislation. Based on the GDPR, our legal basis in that regard is article 6(1)(c).</p>
                        <p className="pb-3">We share such personal data with the tax authorities and our external accountants and other advisors, if relevant. Personal data related to bookkeeping will generally be deleted 5 years after the end of the financial year in which the customer relationship has ended.</p>

                        <h3 className="text-lg font-medium py-2">2.4 Direct marketing and marketing activities</h3>
                        <h4 className="pb-3 italic font-medium">Newsletters</h4>
                        <p className="pb-3">When sending out newsletters, the controller for the personal data processed in that regard is Reel ApS. Please see the contact information under Section 7 below.</p>
                        <p className="pb-3">When you subscribe to our newsletter, we register your name, email address. We process these personal data for the purpose of being able to send you newsletters. The legal basis for our processing is the consent you have given in accordance with section 10 of the Danish Marketing Practices Act (markedsføringsloven) and article 6(1)(a) of the GDPR.</p>
                        <p className="pb-3">Personal data pertaining to our distribution of electronic newsletters will be deleted 1 year after our last newsletter has been distributed, unless you have withdrawn your consent (i.e., unsubscribed) before such time.</p>

                        <h4 className="pb-3 italic font-medium">Social Media</h4>
                        <p className="pb-3">Further, we process your personal data when you visit our page on LinkedIn.</p>
                        <p className="pb-3">As operator of Reel's LinkedIn company page, Reel ApS and LinkedIn operate as joint controllers. Read more about how LinkedIn process personal data in the  <a className="text-blue-500 underline" href="https://legal.linkedin.com/pages-joint-controller-addendum">LinkedIn joint controller addendum</a> and <a className="text-blue-500 underline" href="https://dk.linkedin.com/legal/privacy-policy?">LinkedIn Privacy Policy</a>.</p>

                        <h3 className="text-lg font-medium py-2">2.5 When you visit our website(s)</h3>
                        <p className="pb-3">The controller for the personal data we process when you visit our websites (such as online identificators) is Reel ApS. Please see the contact information under Section 7 below.</p>
                        <p className="pb-3">When you visit our website(s), we use cookies to collect data about your visits, including e.g. your navigation on the website(s), the type of browser you use and your IP address. These data may contain personal data.</p>
                        <p className="pb-3">We collect the data in order to ensure and stable navigation of our website(s).</p>
                        <p className="pb-3">The legal bases for our processing are:</p>
                        <ol className="list-decimal pb-2 pl-8">
                            <li className="py-1">for necessary (technical) cookies: article 6(1)(f) of the GDPR, as we are pursuing our legitimate interest in ensuring functionality and security of our website(s), and</li>
                            <li className="py-1">for other cookies: article 6(1)(a) of the GDPR, as you have given your consent.</li>
                        </ol>
                        <p className="pb-3">We disclose information about your use of the website(s) to any third-party service providers that you have allowed to place cookies when you use the website(s).</p>
                        <p className="pb-3">Personal data contained in cookies will be deleted in accordance with the lifetime/period for each specific cookie. Read more about our cookies, including their lifetime, in our <a href="https://www.iubenda.com/privacy-policy/72778846/cookie-policy" className="text-blue-500 underline underline">Cookie Policy.</a></p>

                        <h2 className="text-xl font-semibold pt-4 pb-2">3. Recipients</h2>
                        <p className="pb-3">Any personal data (contact details) included in particular inquiries, agreements, or other matters, such as customer service activities are shared with our group companies. This is done for the purpose of providing efficient and stable customer service, irrespective of what group company you have concluded an agreement with. That also means that we may have received personal data about you from one of our group companies, e.g., for the purpose of processing such inquiries.</p>
                        <p className="pb-3">We also disclose your personal data to business partners, including external advisors, transmission system operators (TSOs), distribution system operators (DSOs), balancing responsible parties (BRPs), billing system providers, public authorities, recruitment partners, renewables energy developers, business intelligence tools, CRM systems. Also, please see above under each section for the specific recipients under each of the processing purposes.</p>
                        <p className="pb-3">Please see below regarding transfer of your personal data to third countries.</p>

                        <h2 className="text-xl font-semibold pt-4 pb-2">4. Transfer of your personal data to third countries</h2>
                        <p className="pb-3">We transfer your personal data to countries outside the EU and EEA, when making data available to our processors. The basis for such transfer is the EU standard contractual clauses.</p>
                        <p className="pb-3">The recipients are: Microsoft, LinkedIn, HubSpot, Google, Fly.io, Mailchimp, Postmark, Metabase, Reform.app.</p>
                        <p className="pb-3">The third countries are: USA</p>
                        <p className="pb-3">Further, if you would like a copy of the relevant standard contractual clauses, please contact: gdpr@reel.energy</p>

                        <h2 className="text-xl font-semibold pt-4 pb-2">5. Your rights</h2>
                        <p className="pb-3">On request, you have the right to know which personal data Reel processes about you. You also have the right to correct any errors in your personal data.</p>

                        <p className="pb-3">You have the right to receive information about:</p>
                        <ol className="list-decimal pb-2 pl-8">
                            <li className="py-1">What data is processed</li>
                            <li className="py-1">The source of the data</li>
                            <li className="py-1">The purpose of processing the data</li>
                            <li className="py-1">To which recipients or groups of recipients your data is disclosed</li>
                            <li className="py-1">Have any incorrect data about you corrected</li>
                            <li className="py-1">In certain cases, you have the right to have data about you deleted before the normal time of deletion occurs</li>
                            <li className="py-1">In certain cases, you have the right to have the processing of your personal data restricted</li>
                            <li className="py-1">In certain cases, you have the right to receive your personal data in a structured, commonly used, and machine-readable format and to have this personal data transferred from one data controller to another without hindrance</li>
                        </ol>

                        <p className="pb-3">Further, you have the right to withdraw your consent at any time. If you wish to do so, please contact us (see Section 7 below). If you decide to withdraw your consent, it will not affect the lawfulness of our processing of your personal data on the basis of the consent previously given by you and up to the time of withdrawal. If you withdraw your consent, it will not become effective until such time.</p>

                        <h2 className="text-xl font-semibold pt-4 pb-2">6.	Complaint to a supervisory authority</h2>
                        <p className="pb-3">If you want to lodge a complaint with a supervisory authority about our processing of your personal data, you can do so by contacting the Danish Data Protection Agency via their website, www.datatilsynet.dk.</p>
                        <p className="pb-3">You can read more about your rights in the Danish Data Protection Agency's guidelines on data subjects' rights, which is available at www.datatilsynet.dk (in Danish).</p>

                        <h2 className="text-xl font-semibold pt-4 pb-2">7.	Contact us</h2>
                        <p className="pb-3">If you have any questions about how we process personal data, please contact us at gdpr@reel.energy or by letter (see below).</p>

                        <p className="pb-3">The contact details of the group entities (and thereby the data controllers, as listed above) are:</p>

                        <p className="pb-3 italic">
                            Reel ApS <br />
                            Hauser Plads 30A, 1st floor. <br />
                            1127 Copenhagen <br />
                            Denmark
                        </p>

                        <p className="pb-3 italic">
                            Reel Energy ApS <br />
                            Hauser Plads 30A, 1st floor. <br />
                            1127 Copenhagen <br />
                            Denmark
                        </p>

                        <p className="pb-3">Further, certain agreements are entered into with Reel SPVs. A complete list over our SPV entities and the relevant data controller for you can be found <Link to="/spv-list" className="text-blue-500 underline underline">here</Link>.</p>
                    </div>

                </div>
            </div>
        </LayoutSimple>
    );
};
export default PrivacyPolicy;
